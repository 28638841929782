<template>
  <referentiel-list
    :listTitle="'Liste des Types de prescriptions'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
    :dataFormatter="dataFormatter"
    @activeReferentielChange="handleActiveReferentielChanged"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.code"
                @input="handleInput"
                type="text"
                class="form-control"
                id="code"
                name="code"
                placeholder="code"
              />
              <label for="code">Code</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.libelle"
                @input="handleInput"
                type="text"
                class="form-control"
                id="libelle"
                name="libelle"
                placeholder="libelle"
              />
              <label for="libelle">Libelle</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <input
                :value="editableItem.cheminEmploi"
                @input="handleInput"
                type="text"
                class="form-control"
                id="cheminEmploi"
                name="cheminEmploi"
                placeholder="cheminEmploi"
              /> -->
              <multiselect
                name="editableItem.cheminEmploi"
                v-model="editableItem.cheminEmploi"
                :options="['SALARIE', 'INDEPENDANT', 'SALARIE_ET_INDEPENDANT']"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Chemin de l'emploi"
                :preselect-first="false"
              />
              <!-- <label for="cheminEmploi">Chemin d'emploi</label> -->
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <multiselect
                v-model="editableItem.typeActiviteAssocies"
                :options="typeActivites"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                label="libelle"
                placeholder="Types d'activités associées"
                :preselect-first="false"
              />
              <!-- <label for="typeActiviteAssocies">Types d'activités associées</label> -->
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      items: [],
    };
  },
  components: {
    ReferentielList,
    Multiselect
  },
  computed: {
    ...mapGetters({
      typeActivites: "typeActivite/typeActivites",
    }),
    referentielName() {
      return "typePrescription";
    },
    emptyReferentielItem() {
      return {
        name: null,
        code: null,
        privileges: [],
        typeActiviteAssocies: [],
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "libelle", label: "Libelle" },
        { key: "code", label: "Code" },
        { key: "cheminEmploi", label: "Chemin de l'emplois" },

        "actions",
      ];
    },
    selection() {
      return [[], []];
    },
  },
  watch: {
    privileges() {
      this.items = [this.privileges, []];
    },
  },
  created() {
    this.fetchTypeActivites();
  },
  methods: {
    ...mapActions({
      fetchTypeActivites: "typeActivite/fetchTypeActivites",
    }),
    dataFormatter(item) {
      item = {
        ...item,
      };
      return item;
    },
    handleActiveReferentielChanged(item) {
      const itemIds = item.privileges.map((el) => el.id);
      this.items = [
        this.privileges.filter((it) => !itemIds.includes(it.id)),
        item.privileges,
      ];
    },
  },
};
</script>

<style></style>
